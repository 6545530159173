/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/presbycusis/'], ['en', 'https://www.hear.com/hearing-loss/presbycusis/'], ['en-US', 'https://www.hear.com/hearing-loss/presbycusis/'], ['en-CA', 'https://ca.hear.com/hearing-loss/presbycusis/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "age-associated-hearing-loss-explained",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#age-associated-hearing-loss-explained",
    "aria-label": "age associated hearing loss explained permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Age-associated hearing loss explained"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Presbycusis is a slowly progressing sensorineural hearing loss. It always affects both ears to the same degree, and usually starts at the age of 50. One characteristic of presbycusis is that sounds within the high-frequency range are significantly impacted (more so at the start of the illness) more than deeper sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Consequently, speech intelligibility is hampered more severely than the capacity to hear sounds – especially when impaired hearing is subjected to an intensely noisy environment. Doctors use the term “cocktail party effect” to refer to the ability to focus on a single speaker or conversation in a noisy setting. In other words, if a person can hear what someone is saying in a crowded, noisy party, even if they are half way across the room, it is more than likely that he or she is experiencing the cocktail party effect."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-of-presbycusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-of-presbycusis",
    "aria-label": "causes of presbycusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes of presbycusis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Presbycusis develops for a number of reasons, some of which include a wide range of ear disorders. Damaging external influences also play a significant role. Typically, these are patients who have been frequently subjected to blaring noise throughout their lives. Another possible cause is certain medications, commonly referred to as ototoxic medication, that can damage the ear in the long run."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/presbycusis-audiogram.jpg",
    alt: "Audiogram",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There is also a connection between presbycusis and risk factors such as smoking, high blood pressure, and diabetes mellitus. Age-associated processes, like progressive impairment of the hair cells, also play an important role. The hair cell receptors are located in the inner ear’s organ of Corti and are responsible for the actual hearing process. If these are damaged due to continuous noise pollution, for example, external sounds can no longer be properly perceived, resulting in impaired hearing. A genetic predisposition may also be a contributing factor."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-of-presbycusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-of-presbycusis",
    "aria-label": "symptoms of presbycusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms of presbycusis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Initially, patients usually complain about problems with speech intelligibility, especially when subjected to a lot of background noise, or in the presence of several conversation partners (cocktail party effect). This is because the high-frequency range is impacted by the presence of presbycusis. As the aging process advances or with the disease’s progression, the degree of hearing impairment also steadily increases in other frequency ranges."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Additionally, patients suffering from presbycusis are faced with increased noise sensitivity. This means that the “discomfort threshold” for a very loud tone or sound is lowered. Another symptom specifically occurs in very quiet environments: presbycusis patients frequently complain of a buzzing or ringing sound (tinnitus)."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosis",
    "aria-label": "diagnosis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To correctly diagnose presbycusis or age-associated hearing loss, and initiate the appropriate treatment with a hearing aid, it is imperative to exclude other causes or illnesses. Among other things, this includes the examination of the ear using a specialist microscope (ear microscopy). In patients suffering from presbycusis, the ENT specialist will usually find a normally structured eardrum (i.e. no tear or hole in the eardrum). A pure-tone and speech audiogram are also performed to ultimately confirm the diagnosis. If all other causes can be excluded, the ENT specialist will usually recommend the provision of a hearing aid. Modern hearing aids can treat presbycusis efficiently and restore near-perfect comprehension of speech, tones and sounds."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment-of-presbycusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment-of-presbycusis",
    "aria-label": "treatment of presbycusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment of presbycusis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The use of a hearing aid is recommended as treatment for age-associated hearing loss. (Medicinal treatment leading to complete restoration of hearing capacity is currently unavailable.) The long-term consequences of untreated age-associated hearing loss could include increased social isolation or depression. The use of a hearing aid system can, however, help diminish the debilitating symptoms and return some quality of life back to the afflicted person."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com can assist you in finding the perfect hearing aid solution for your current auditory situation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "pure-tone-audiometry",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pure-tone-audiometry",
    "aria-label": "pure tone audiometry permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pure-tone audiometry"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During pure-tone audiometry, patients are subjected to high and low tones at different volumes. The patient is asked to respond to the sounds until they are unable to hear the faintest tone. The results of this exam will consequently yield an auditory threshold curve."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If this examination were to be performed on a patient with presbycusis, a loss in the perception of high tones would become apparent: in such cases, the auditory threshold curve would be inferior, particularly in the high-frequency range."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This type of hearing loss goes beyond the maximum age-associated hearing loss. If the illness has progressed further, deeper tones could also be affected."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "speech-audiometry",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#speech-audiometry",
    "aria-label": "speech audiometry permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Speech audiometry"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During the speech audiometry exam, a certain number of words are presented to the patient via headphones and he/she will have to repeat them. Once the test is complete, the information collected from the exam will help doctor determine if there is a deficit in speech comprehension."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If speech audiometry is performed on a patient with presbycusis, the examination will reveal impaired speech comprehension."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
